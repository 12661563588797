const closeAccordion = (el) => {
    el.parentElement.classList.remove('active');
    el.nextElementSibling.style.height = '0';
}

const openAccordion = (el) => {
    const accordionHeight = el.nextElementSibling.scrollHeight;

    el.parentElement.classList.add('active');
    el.nextElementSibling.style.height = (accordionHeight + (18 * 2)) + 'px';
}

const toggleAccordion = (el) => {
    if (el.parentElement.classList.contains('active')) {
        return closeAccordion(el);
    }

    openAccordion(el);
}

const closeAllAccordions = (expect = null) => {
    const accordionHeads = document.querySelectorAll('.accordion .accordion-head');

    accordionHeads.forEach(el => {
        if (!expect || el !== expect) {
            return closeAccordion(el);
        }
    });
}

document.addEventListener('DOMContentLoaded', () => {
    const accordionHeads = document.querySelectorAll('.accordion .accordion-head');

    accordionHeads.forEach(el => {
        el.addEventListener('click', (e) => {
            e.preventDefault();

            toggleAccordion(el);

            closeAllAccordions(el);
        })
    })
});
